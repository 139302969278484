@import "src/assets/scss/_variable";
// share css

.btn-green {
    background-color: $green !important;
    color: $white;
}

.btn {
    font-weight: 500 !important;
}

.btn-outline{
    border-color: $blue-dark !important;
    color: $blue-dark;
    font-size: $font-7-size;
    &:hover,
    &:active {
        border-color: $blue-dark;
        color: $white !important;
        background-color: $blue-dark !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-dark, 0.5);
    }
}

.btn-outline-call{
    background-color: $blue-dark !important;
    border-color: $blue-dark !important;
    color: $white;
    font-size: $font-6-size;

    &:hover,
    &:active {
        border-color: $blue-dark;
        color: $blue-dark;
        background-color: $white;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-dark, 0.5);
    }
}

.btn-outline-white{
    border-color: $white !important;
    color: $white !important;
    font-size: $font-6-size !important;

    &:hover,
    &:active {
        border-color: $white;
        color: $black !important;
        background-color: $white !important;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($white, 0.5);
    }
}

.btn-reserve,
.btn-more,
.btn-result {
    background-color: $green;
    border-color: $green;
    color: $white;
    font-size: $font-6-size;
}
//
// .btn-custom {
//     background-color: $color-custom;
//     border-color: $color-custom;
//     color: $white;
//     font-size: $font-6-size;
// }

.btn-outline-custom {
    border-color: $color-custom;
    color: $color-custom;
    min-width: 260px;
    font-size: $font-7-size;

    &:hover,
    &:active {
        background-color: $color-custom !important;
        border-color: $color-custom !important;
        color: $white;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($color-custom, 0.5);
    }
}


.btn-outline-line {
    border-color: $green-color;
    color: $green-color;
    font-size: $font-7-size;
    background: $white;
    &:hover,
    &:active {
        background-color: $green-color !important;
        border-color: $green-color !important;
        color: $white;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($green-color, 0.5);
    }
}

.btn-line:after {
    content:'';
    border-top: 1px solid $text-normal-color-light;
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left:0;
    top:50%;
}

.btn-outline-grey {
    border-color: $text-normal;
    color: $text-normal;
    font-size: $font-7-size;
    position: absolute;
    top: 0;
    background: $white;
    bottom: 0;
    transform: translate(-50%,0);
    z-index: 1;
    min-width: 125px;
    &:hover,
    &:focus {
        background-color: $text-normal !important;
        border-color: $text-normal !important;
        color: $white;
        box-shadow: 0 0 0 0.2rem rgba($text-normal, 0.5);
    }
    .icon-text2 {
        display: inline-block;
    }
    .icon-text1 {
        display: none;
    }
    .icon-arrow {
        transform: rotate(180deg);
        display: inline-block;
    }
    &.collapsed {
        .icon-text2 {
            display: none;
        }
        .icon-text1 {
            display: inline-block;
        }
        .icon-arrow {
            transform: rotate(0deg);
            display: inline-block;
        }
    }
}

.btn-outline-grey-normal {
    border-color: $text-normal;
    color: $text-normal;
    font-size: $font-7-size;
    background: $white;
    &:hover,
    &:active {
        background-color: $text-normal !important;
        border-color: $text-normal !important;
        color: $white;
    }
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($text-normal, 0.5);
    }
}

.btn-outline-month {
    border-color: $text-normal-color-light;
    color: $text-normal;
    font-size: $font-7-size;
    background: $white;
    width:96%;

    input {
        position: absolute;
        z-index: -1;
    }
    &:hover,
    &.active {
        background-color: $blue-color !important;
        border-color: $blue-color !important;
        color: $white;
    }
    &.focus {
        box-shadow: 0 0 0 0.2rem rgba($blue-color, 0.5);
    }
}

.btn-close {
    border-color: $blue-dark !important;
    background-color: $blue-dark !important;
    color: $white;
    font-size: $font-7-size;
}