// color
$theme-color                : #25BDA3;
$white                      : #FFFFFF;
$black                      : #272922;
$haze                       : #ECEEF3;
$haze-light                 : #F7F8FA;
$haze-dark                  : #8A9BAD;
$blue                       : #2B71FB;
$blue-dark                  : #0A7099;
$text-dark                  : #465668;
$text-light                 : #8A9BAD;
$green                      : #28A744;
$base-border                : #D4DAE2;
$red                        : #FF010A;
$orange                     : #FE6C04;
$text-normal                : #465668;
$fill-backgrond             : #F5F6F9;

$red-color-dark             : #B90001;
$red-color                  : #FF010A;
$green-color                : #28A744;
$text-normal-color          : #465668;
$text-normal-color-light    : #8A9BAD;
$text-normal-color-dark     : #344251;
$grey-color-dark            : #6D6D6D;
$grey-color                 : #797979;

$fill-backgrond-light       : #f1f1f1;
$orange-color               : #FF6704;
$blue-color                 : #0A7099;

$color-custom               : #E57D9F;

// font
$font-1-size                : 42px;

$font-2-size                : 32px;
$font-2-height              : 38px;

$font-3-size                : 28px;
$font-3-height              : 36px;

$font-4-size                : 24px;
$font-4-height              : 30px;

$font-5-size                : 18px;
$font-5-height              : 26px;

$font-6-size                : 16px;
$font-6-height              : 24px;

$font-7-size                : 14px;
$font-7-height              : 20px;

$font-8-size                : 12px;
$font-8-height              : 18px;

$font-9-size                : 10px;
$font-9-height              : 16px;

$font-10-size               : 9px;
$font-10-height             : 10px;

// utility
$base-radius               : 4px;
$box-shadow                : 0 3px 4px rgba(52,66,81,0.2), 0 0 0 1px rgba(52,66,81,0.1);
