@import 'src/assets/scss/_variable';
@import 'src/assets/scss/_button';
// @import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,200;0,300;0,600;1,300&display=swap');
// :host {
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//     font-size: 14px;
//     color: $text-normal-color;
//     box-sizing: border-box;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

.form-control {
    font-weight: 500;
}
.search-result {
    background-color: $haze;
    .package__reserve {
        display: block;
    }
    .package__realtime-reserve {
        display: none;
    }
    .package__card-image {
        max-width: 266px !important;
    }
    .package__card-title {
        -webkit-line-clamp: 2 !important;
        font-size: $font-6-size !important;
        color: $text-dark;
    }
    .package__card-status {
        font-size: $font-8-size !important;
        .col-6 {
            flex-basis: 30%;
            white-space: nowrap;
            line-height: 20px;

            &.location {
                white-space: normal;
                padding-right: 10px;
                margin-left: 20px;
            }

            img.location {
                position: absolute;
                left: -20px;
                top: 4px;
            }
        }
    }
    .package__recommend {
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    .contact-panel-bottom {
        display: none;
    }
    .package__price-net {
        min-width: 150px;
        div {
            display: inline-block;
            margin-right: 5px;
        }
    }
}
.search-detail {
    .package__reserve {
        display: none;
    }
    .package__card {
        .contact-panel-bottom {
            display: none;
        }
    }
}

.contact-panel {
    .btn-reserve {
        line-height: $font-3-height;
    }
    .btn-outline-call {
        line-height: $font-3-height;
    }
    .btn-outline-line {
        line-height: $font-3-height;
    }
}

@media (max-width: 991.98px) {
    .search-detail {
        padding: 50px 0 60px;
        .container {
            max-width: 500px !important;
        }
        .content-left {
            margin: auto;
        }
        .content-right {
            display: none;
        }
        .package__detail-other {
            margin-top: 10px !important;
        }
        .package__card {
            flex-direction: column;
            .package__card-image {
                max-width: inherit !important;
            }
            .package__tag {
                display: none !important;
            }
        }
        .detailpage__price {
            display: block !important;
        }
        .package__reserve {
            display: block;

            div.package__price-net div {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 8px;
            }

            button {
                display: none;
            }
        }
        .contact-panel-bottom {
            display: block !important;
            .btn-reserve {
                line-height: $font-4-height;
            }
            .btn-outline-call {
                line-height: $font-4-height;
            }
            .btn-outline-line {
                line-height: $font-4-height;
            }
        }
        .package__realtime-reserve {
            display: none;
        }
    }
    .search-result {
        .container {
            max-width: 98% !important;
        }
        .contact-panel-bottom {
            display: none;
        }
        .package__card-status {
            .col-6 {
                flex-basis: 50%;
                white-space: normal;
            }
        }
    }
}

@media (max-width: 767.98px) {
    .search-result {
        .container {
            max-width: 96% !important;
        }
        .package__card-status {
            .col-6 {
                flex-basis: 50%;
                white-space: normal;
            }
        }
        .package__detail-other {
            .package__card-status {
                display: none;
            }
        }
        .package__reserve {
            width: 100%;
            font-size: $font-4-size;
            line-height: $font-7-size;
            display: flex;
            justify-content: space-between;
            .package__price-net {
                flex-basis: 100%;
                span {
                    font-size: $font-4-size !important;
                    color: $black;
                }
            }
        }

        .package__price-net div {
            display: block;
        }
    }
    .search-detail {
        .contact-panel-bottom {
            display: block !important;
            .btn-reserve {
                line-height: $font-4-height;
            }
            .btn-outline-call {
                line-height: $font-4-height;
            }
            .btn-outline-line {
                line-height: $font-4-height;
            }
        }
        .package__realtime-reserve {
            display: none;
        }
    }
}

.search__reset {
    font-size: $font-8-size;
    text-decoration: underline;
    color: $text-normal-color-light;
    &:hover {
        color: $text-normal-color-dark;
        text-decoration: none;
    }
}
.search__month {
    .col-6 {
        &:nth-child(odd) {
            text-align: left;
        }
        &:nth-child(even) {
            text-align: right;
        }
    }
}

.result__nav {
    font-size: $font-4-size;
    line-height: $font-4-height;
    .result__title {
        color: $text-normal-color-dark;
    }

    .result__result {
        font-size: $font-6-size;
    }
}

#tab-content-period.collapse:not(.show),
#tab-content-period.collapsing {
    height: 200px;
}

#tab-content-booking.collapse:not(.show),
#tab-content-booking.collapsing {
    height: 200px;
}

.text-small {
    font-size: $font-8-size;
}

.text-xsmall {
    font-size: $font-9-size;
    line-height: $font-9-height;
}

.box {
    box-shadow: $box-shadow;
    border-radius: $base-radius;
}

.breadcrumb__wrap {
    background: $white;

    .breadcrumb {
        background: $white;
        font-size: 12px;
    }

    .breadcrumb-item + .breadcrumb-item::before {
        content: '>';
    }

    .breadcrumb-item.active {
        color: $text-normal-color-light;
    }
    a {
        color: $text-normal-color-light;
    }
}

.text-cert,
.text-cert span {
    font-size: $font-8-size;
}

.package {
    .package__tag {
        display: none;
    }
    .btn-reserve {
        display: none !important;
    }
    .package__detail-other {
        display: none;
    }
    .package__discount {
        display: none;
    }
}

.package__hotdeal {
    background-color: $red-color-dark;
    background-position: top;
    background-repeat: no-repeat;

    .package__price-net {
        color: $red-color !important;
    }

    .package__discount {
        display: block;
    }
    .btn-reserve {
        display: block !important;
    }

    .btn-more {
        display: none !important;
    }
    .package__detail-other {
        display: block !important;
    }
    .package__tag {
        display: flex !important;
    }
}
.package__all-link {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

.package__tag {
    background-color: darken($red-color-dark, 5);
    color: $white;
    font-size: $font-8-size;
}

.package__discount {
    right: 8px;
}

.package__discount-text {
    font-size: $font-2-size;
    font-weight: bold;
    left: 8px;
    top: 5px;
    line-height: $font-2-height;
    span {
        font-size: $font-5-size;
    }
}

.package__realtime-hot {
    color: darken($red-color, 10%);
}

.package__detail-mobile {
    display: none;
}
.header__menu {
    a {
        color: $text-normal-color;
    }
}

// carousel -- specific
#carousel-banner {
    .carousel-indicators {
        bottom: -44px;
        li {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $white;
            opacity: 1;
        }
        .active {
            background-color: $grey-color-dark;
        }
    }
}

.carousel-control-prev,
.carousel-control-next {
    width: 5%;

    @include media-breakpoint-up(md) {
        width: 10%;
    }
}

.carousel-control-prev {
    left: 10px;

    .carousel-control-prev-icon {
        background-image: url('/assets/images/icon-arrow-left.svg');
    }
}

.carousel-control-next {
    left: 330px;

    .carousel-control-next-icon {
        background-image: url('/assets/images/icon-arrow-right.svg');
    }
}

.owl-carousel {
    -ms-touch-action: pan-y;
    touch-action: pan-y;

    .owl-stage {
        display: flex;
    }

    .owl-item {
        touch-action: auto;
        -ms-touch-action: auto;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1, 1);

        img {
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1, 1);
        }
    }
}

@include media-breakpoint-up(md) {
    .carousel-control-prev {
        left: -6rem;
        @media (min-width: 991.98px) and (max-width: 1139px) {
            left: -2rem;
        }
    }

    .carousel-control-next {
        left: auto;
        right: -6rem;
        @media (min-width: 991.98px) and (max-width: 1139px) {
            right: -2rem;
        }
    }
}

.mobile__slide {
    overflow: scroll;
    margin-right: -10px;
}

// action__box

@include media-breakpoint-down(md) {
    .popular__countries-list {
        a {
            margin-bottom: 1rem !important;
        }
    }
    // top-menu
    .header__wide {
        .user__profile {
            display: none;
        }
    }
    .topmenu__wide {
        display: none;
    }
    .user__profile-mobile {
        display: block !important;
    }

    .result__nav {
        margin-top: -10px;
        .result__title {
            font-size: $font-6-size;
        }
        .result__result {
            display: none;
        }
    }
    .navbar {
        height: 60px;
    }
}

@include media-breakpoint-down(sm) {
    // footer
    .footer__link {
        flex-direction: column;
    }
    .scrollTop {
        display: none;
    }

    // carousel-banner
    .section.banner {
        padding-top: 1rem !important;
        padding-bottom: 1.5rem !important;

        .carousel-indicators {
            bottom: -35px !important;
        }
    }
    .package__list {
        width: 300px !important;
        min-width: 300px !important;
    }
    // country list
    .popular__countries-list h3 {
        font-size: $font-4-size;
        width: 100%;
        text-align: center;
    }
    .popular__countries-list > a:nth-child(1) {
        flex-basis: 100% !important;
    }
    .popular__countries-list > a:nth-child(even) {
        margin-right: 5px;
    }
    .popular__countries-list > a {
        flex-basis: 49% !important;
        margin-bottom: 0.7rem !important;
    }
    // search-detail

    .table-content-booking {
        tr td:nth-child(3),
        tr th:nth-child(3) {
            display: none;
        }
    }
}

.about-us-breadcrumb {
    @media (min-width: 992px) {
        .breadcrumb-container {
            margin: -45px -22px 0;
            border-bottom: solid 1px #d4dae2;

            ol.breadcrumb {
                padding: 25px 0 20px;
                margin-bottom: 0;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.media {
    // fix the youtube video is not showing
    display: block !important;
}

.mobile {
    > .modal-content {
        background: unset;
        border: unset;
    }
}
