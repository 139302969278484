$custom-file-text: ();
@import '/node_modules/bootstrap/scss/bootstrap';

@import 'assets/scss/variable';
@import 'assets/scss/button';
@import 'assets/scss/_custom';
@import 'assets/scss/override-slick-js-gift-a-wow';
@import 'assets/scss/override-ckedit-content';

@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@300;500&display=swap');

body {
    font-family: 'Mitr', sans-serif;
    font-size: 14px;
    line-height: 1.4;
    color: $text-dark;
    font-weight: 300;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    padding: 0;

    @media (max-width: 1281px) {
        max-width: 960px !important;
    }

    @media (min-width: 1281px) {
        max-width: 1140px;
    }
}

.is-desktop {
    display: none;

    @media (min-width: 992px) {
        display: block;
    }
}

.is-mobile {
    display: block;

    @media (min-width: 992px) {
        display: none;
    }
}
