.slick-prev,
.slick-next {
    width: 50px;
    height: 50px;
    background: #ffffff;
    border-radius: 50%;
    z-index: 1;
}

@media (hover: hover) {
    .slick-prev,
    .slick-next {
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
    }
    .slick-prev:hover,
    .slick-next:hover {
        background: #f9f6f0;
    }
    .slick-prev:hover:before,
    .slick-next:hover:before {
        color: #434d59;
        opacity: 1;
    }
}

.slick-prev:active,
.slick-prev:focus,
.slick-next:active,
.slick-next:focus {
    background: #f9f6f0;
    color: #434d59;
    opacity: 1;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'icon' !important;
    font-size: 24px;
    font-size: 1.5rem;
    color: #5e6771;
    opacity: 0.3;
}

.slick-prev:before {
    content: '\e902';
}

.slick-next:before {
    content: '\e903';
}

.section-shop .slick-slider {
    width: 100%;
    background-color: transparent;
}

.section-shop .slick-slider .slick-track {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 30px 0 15px;
}

@media screen and (min-width: 768px) {
    .section-shop .slick-slider .slick-track {
        padding: 50px 0 30px;
    }
}

.section-shop .slick-slider .slick-slide {
    float: none;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    margin: 10px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.section-shop .slick-slider .slick-slide.slick-current,
.section-shop .slick-slider .slick-slide.slick-center {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

@media screen and (min-width: 768px) {
    .section-shop .slick-slider .slick-slide {
        margin: 10px 22px;
    }
}

.section-shop .slick-next {
    right: -20px;
}

@media screen and (min-width: 1200px) {
    .section-shop .slick-next {
        right: -60px;
    }
}

.section-shop .slick-prev {
    left: -20px;
}

@media screen and (min-width: 1200px) {
    .section-shop .slick-prev {
        left: -60px;
    }
}
