.editor-view {
    font-family: 'Krub Regular';
    font-size: 18px !important;
    color: #3b3d40 !important;

    h2 {
        font-family: 'Prompt Bold';
        font-size: 30px;
        font-weight: bold;
        color: #434f5d !important;
    }
    h3 {
        font-family: 'Prompt Bold';
        font-size: 26px;
        font-weight: bold;
        color: #434f5d !important;
    }
    h4 {
        font-family: 'Prompt Bold';
        font-size: 22px;
        font-weight: bold;
        color: #434f5d !important;
    }

    p {
        font-family: 'Krub Regular';
        font-size: 18px;
        color: #3b3d40 !important;
    }

    .image > img {
        max-width: 100% !important;
        height: auto !important;
    }

    .image.image-style-align-left {
        position: relative;
        float: left;
    }

    .image,
    .image.image-style-align-center {
        position: relative;
        text-align: center;
    }
    .image.image-style-align-right {
        position: relative;
        float: right;
    }
}
